import { ref } from 'vue';
import axios, { AxiosError } from 'axios';
import useCommonUtils from '@/composables/app/useCommonUtils';
import useToast from '@/composables/app/useToast';
import useEnv from '@/composables/app/useEnv';

const useCrud = (restUrl, usingNodeApp = false) => {
  const { isObject, isArray } = useCommonUtils();
  const { showDefaultToast } = useToast();
  const { nodeServerUrl } = useEnv();

  if (usingNodeApp) {
    if (restUrl?.slice(-1) === '/') restUrl = restUrl?.slice(0, -1);
    restUrl = `${nodeServerUrl}/api/v1${restUrl}`;
  }

  const getRequestLoading = ref(false);
  const getSingleRequestLoading = ref(false);
  const postRequestLoading = ref(false);
  const deleteRequestLoading = ref(false);

  const cancelToken = ref(null);
  const lastGetRequestData = ref(undefined);
  const firstGetRequestLoading = ref(true);
  const firstGetSingleRequestLoading = ref(true);

  const getData = async (queries = undefined, options = {}) => {
    try {
      getRequestLoading.value = true;
      queries = processRequestQueryParams(queries);

      if (cancelToken.value) {
        cancelToken.value.cancel('New request triggered');
      }
      cancelToken.value = axios.CancelToken.source();

      const response = await axios.get(`${restUrl}${queries}`, {
        cancelToken: cancelToken.value ? cancelToken.value.token : null,
        headers: options?.headers ? options.headers : {},
        responseType: options?.responseType ? options?.responseType : undefined
      });

      if (response instanceof AxiosError) {
        throw response;
      }

      if (options?.callback && typeof options.callback === 'function')
        options.callback(response);

      if (response.data) {
        getRequestLoading.value = false;
        firstGetRequestLoading.value = false;
        lastGetRequestData.value = response.data;
        return response.data;
      }
      getRequestLoading.value = false;
      firstGetRequestLoading.value = false;
      return [];
    } catch (error) {
      if (error && error.message === 'New request triggered')
        return lastGetRequestData.value;

      if (options?.callback && typeof options.callback === 'function')
        options.callback(error.response, true);

      firstGetRequestLoading.value = false;
      getRequestLoading.value = false;
      return [];
    }
  };

  const getSingleData = async (id, queries = undefined, options = {}) => {
    try {
      getSingleRequestLoading.value = true;
      queries = processRequestQueryParams(queries);

      const response = await axios.get(`${restUrl}${id}/${queries}`, {
        headers: options?.headers ? options.headers : {},
        responseType: options?.responseType ? options?.responseType : undefined
      });

      if (response instanceof AxiosError) {
        throw response;
      }

      if (options?.callback && typeof options.callback === 'function')
        options.callback(response);

      if (response.data) {
        getSingleRequestLoading.value = false;
        firstGetSingleRequestLoading.value = false;
        return response.data;
      }
      firstGetSingleRequestLoading.value = false;
      getSingleRequestLoading.value = false;
      return null;
    } catch (error) {
      firstGetSingleRequestLoading.value = false;
      getSingleRequestLoading.value = false;

      if (options?.callback && typeof options.callback === 'function')
        options.callback(error.response, true);

      return null;
    }
  };

  const createData = async (params, queries = undefined, options = {}) => {
    try {
      postRequestLoading.value = true;
      queries = processRequestQueryParams(queries);

      const response = await axios.post(`${restUrl}${queries}`, params, {
        headers: options?.headers ? options.headers : {},
        responseType: options?.responseType ? options?.responseType : undefined
      });

      if (response instanceof AxiosError) {
        throw response;
      }

      if (options?.callback && typeof options.callback === 'function')
        options.callback(response);

      if (response.data) {
        postRequestLoading.value = false;
        return response.data;
      }

      postRequestLoading.value = false;
      return null;
    } catch (error) {
      if (options?.callback && typeof options.callback === 'function')
        options.callback(error.response, true);

      if (error?.response?.data?.message) {
        showDefaultToast('Error', 'danger', error?.response?.data?.message);
      }

      postRequestLoading.value = false;
      return null;
    }
  };

  const updateData = async (params, id, queries = undefined, options = {}) => {
    try {
      postRequestLoading.value = true;
      queries = processRequestQueryParams(queries);

      const response = await axios.patch(
        `${restUrl}${id ? `${id}/` : ''}${queries}`,
        params,
        {
          headers: options?.headers ? options.headers : {},
          responseType: options?.responseType
            ? options?.responseType
            : undefined
        }
      );

      if (response instanceof AxiosError) {
        throw response;
      }

      if (options?.callback && typeof options.callback === 'function')
        options.callback(response);

      if (response.data) {
        postRequestLoading.value = false;
        return response.data;
      }
      postRequestLoading.value = false;
      return null;
    } catch (error) {
      if (options?.callback && typeof options.callback === 'function')
        options.callback(error.response, true);

      if (error?.response?.data?.message) {
        showDefaultToast('Error', 'danger', error?.response?.data?.message);
      }

      postRequestLoading.value = false;
      return null;
    }
  };

  const updateFullData = async (
    params,
    id,
    queries = undefined,
    options = {}
  ) => {
    try {
      postRequestLoading.value = true;

      queries = processRequestQueryParams(queries);

      const response = await axios.put(
        `${restUrl}${id ? `${id}/` : ''}${queries}`,
        params,
        {
          headers: options?.headers ? options.headers : {}
        }
      );

      if (response instanceof AxiosError) {
        throw response;
      }

      if (options?.callback && typeof options.callback === 'function')
        options.callback(response);

      if (response.data) {
        postRequestLoading.value = false;
        return response.data;
      }

      postRequestLoading.value = false;

      return null;
    } catch (error) {
      if (options?.callback && typeof options.callback === 'function')
        options.callback(error.response, true);

      if (error?.response?.data?.message) {
        showDefaultToast('Error', 'danger', error?.response?.data?.message);
      }

      postRequestLoading.value = false;

      return null;
    }
  };

  const deleteData = async (id, options = {}) => {
    try {
      deleteRequestLoading.value = true;

      const queries = processRequestQueryParams(options?.queries);

      const response = await axios.delete(
        `${restUrl}${id ? `${id}/` : ''}${queries}`,
        {
          headers: options?.headers ? options.headers : {}
        }
      );

      if (response instanceof AxiosError) {
        throw response;
      }

      if (options?.callback && typeof options.callback === 'function')
        options.callback(response);

      deleteRequestLoading.value = false;
      if (response.data) return response.data;
      else if ([200, 201, 202, 203, 204, 206].includes(response.status)) {
        return options?.returnExactResponse ? response.data : true;
      } else return false;
    } catch (error) {
      if (options?.callback && typeof options.callback === 'function')
        options.callback(error.response, true);

      if (error?.response?.data?.message) {
        showDefaultToast('Error', 'danger', error?.response?.data?.message);
      }

      deleteRequestLoading.value = false;
      return false;
    }
  };

  const processRequestQueryParamsWithBestPractice = (params) => {
    if (!params || !isObject(params)) return '';
    const processedParams = Object.keys(params).reduce((result, key) => {
      if (isObject(params[key])) {
        const processedNestedParams = Object.keys(params[key]).reduce(
          (res, nestedKey) => {
            const filterKey = `${key}[${nestedKey}]`;
            return {
              ...res,
              [filterKey]: isArray(params[key][nestedKey])
                ? params[key][nestedKey].join(',')
                : params[key][nestedKey]
            };
          },
          {}
        );
        return { ...result, ...processedNestedParams };
      } else {
        if (isArray(params[key]))
          return { ...result, [key]: params[key].join(',') };
        else return { ...result, [key]: params[key] };
      }
    }, {});

    return `?${new URLSearchParams(processedParams).toString()}`;
  };

  const processRequestQueryParams = (params) => {
    if (!params || !isObject(params)) return '';

    const processedParams = Object.keys(params).reduce((result, key) => {
      if (!isObject(params[key])) {
        if (isArray(params[key]))
          return { ...result, [key]: params[key].join(',') };
        else return { ...result, [key]: params[key] };
      } else {
        const processed = Object.keys(params[key]).reduce(
          (nestedResult, nestedKey) => {
            if (!isObject(params[key][nestedKey])) {
              if (isArray(params[key][nestedKey]))
                return {
                  ...nestedResult,
                  [nestedKey]: params[key][nestedKey].join(',')
                };
              else
                return { ...nestedResult, [nestedKey]: params[key][nestedKey] };
            } else {
              return {
                ...nestedResult,
                [nestedKey]: JSON.stringify(params[key][nestedKey])
              };
            }
          },
          {}
        );
        return { ...result, ...processed };
      }
    }, {});
    return `?${new URLSearchParams(processedParams).toString()}`;
  };
  const bulkPatch = async (params) => {
    try {
      postRequestLoading.value = true;
      const response = await axios.patch(`${restUrl}`, params);
      if (response.data) {
        postRequestLoading.value = false;
        return response.data;
      }
      postRequestLoading.value = false;
      return null;
    } catch (error) {
      postRequestLoading.value = false;
      return null;
    }
  };
  const putData = async (params) => {
    try {
      postRequestLoading.value = true;
      const response = await axios.put(`${restUrl}`, params);
      if (response.data) {
        postRequestLoading.value = false;
        return response.data;
      }
      postRequestLoading.value = false;
      return null;
    } catch (error) {
      postRequestLoading.value = false;
      return null;
    }
  };

  return {
    getRequestLoading,
    getSingleRequestLoading,
    postRequestLoading,
    deleteRequestLoading,

    lastGetRequestData,
    firstGetRequestLoading,
    firstGetSingleRequestLoading,

    getData,
    getSingleData,
    createData,
    updateData,
    updateFullData,
    deleteData,
    processRequestQueryParamsWithBestPractice,
    processRequestQueryParams,
    bulkPatch,
    putData
  };
};

export default useCrud;
