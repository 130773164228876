<script setup>
import { ref, computed, readonly } from 'vue';
import useCommonUtils from '@/composables/app/useCommonUtils';
import useDate from '@/composables/app/useDate';

const props = defineProps({
  process: { type: Object, required: true }
});

const { stringCapitalize, getDeepValueFromObject } = useCommonUtils();
const { formatDate } = useDate();

const dataMapper = readonly({
  status: {
    pending_worker: 'pending_worker_status',
    dvms: 'dvms_status',
    default: false
  },
  sent_on: {
    pending_worker: 'pending_worker_sent_on',
    dvms: 'dvms_sent_on',
    default: null
  },
  processing: {
    pending_worker: 'pending_worker_sent_on',
    dvms: 'dvms_sent_on',
    default: null,
    callback: (value) => !Boolean(value)
  },
  logs: {
    pending_worker: 'pending_woker_log',
    dvms: 'dvms_log',
    default: []
  }
});

const showLogs = ref(false);

const type = computed(() => {
  // return 'pending_worker';
  return props.process?.type;
});

const title = computed(() => {
  return `${stringCapitalize(
    type.value.split('_').join(' ')
  )} status of applicant <a class="link-btn" target="_blank" href="${`/recruitments/candidates/${props.process?.applicant?.candidate_id}`}">${
    props.process?.applicant?.candidate_name
  }</a> on job <a class="link-btn" target="_blank" href="/recruitments/jobs/${
    props.process?.applicant?.job_id
  }">${props.process?.applicant?.job_position} (${
    props.process?.applicant?.job_id
  })</a> `;
});

const data = computed(() => {
  const data = Object.entries(dataMapper).reduce((acc, [key, value]) => {
    const dataKey = value[type.value] || '';

    let result = getDeepValueFromObject(props.process, dataKey);
    if (typeof value?.callback === 'function') result = value.callback(result);

    return {
      ...acc,
      [key]: typeof result !== 'undefined' ? result : value?.default
    };
  }, {});

  return data;
});
</script>

<template>
  <div class="w-100 rounded-1 shadow-small border mb-2">
    <!-- accordion header -->
    <div
      class="w-100 p-3 bg-color-1 dark-bg-color-12"
      :class="[showLogs ? 'border-b' : 'rounded-1']"
    >
      <div class="flex-between gap-3 text-semi-contrast">
        <div
          class="flex-1 text-12px"
          v-html="title"
        ></div>
        <div class="flex-end gap-2">
          <FeatherIcon
            v-if="data.processing"
            size="14"
            type="loader"
            animation="spin"
            animation-speed="slow"
          />
          <span class="text-12px">{{ data?.status ? '✅' : '❌' }}</span>
          <FeatherIcon
            type="chevron-down"
            size="14"
            class="cursor-pointer transition-300"
            :class="[showLogs ? 'rotate-180deg' : '']"
            @click="showLogs = !showLogs"
          />
        </div>
      </div>
    </div>

    <div
      v-if="showLogs"
      class="p-3 text-12px"
    >
      <div class="flex-between gap-3 text-semi-contrast mb-3 border-bottom">
        <div class="font-bold mb-2">Logs</div>
        <div class="">
          {{
            `Last updated: ${formatDate(data?.sent_on, 'dd/MM/yyyy hh:mm a')}`
          }}
        </div>
      </div>

      <ul class="">
        <li
          v-for="(log, index) in data.logs"
          :key="index"
          class="text-12px mb-2"
        >
          {{ `${log?.status === 'success' ? '✅' : '❌'} ${log?.message}` }}
        </li>
      </ul>
    </div>
  </div>
</template>
