import { computed, onMounted, watch, isRef } from 'vue';
import { plural, singular, isSingular } from 'pluralize';
import useSettings from '@/composables/app/useSettings';
import useCommonUtils from '@/composables/app/useCommonUtils';

import { cloneDeep } from 'lodash';

const useRenameTableHeader = (
  columns = null,
  headerClass = '.VueTables__heading'
) => {
  const { teamSettings, isTeamSettingsLoaded } = useSettings();
  const { textCapitalize } = useCommonUtils();

  const menuNames = computed(() => teamSettings.value?.sidebarMenuNames || {});

  const matchingSidebarKeys = computed(() => [
    'organizations',
    'companies',
    'business_units',
    'departments',
    'jobs',
    'candidates',
    'applicants'
  ]);

  const matchingItems = computed(() => {
    return matchingSidebarKeys.value.map((sidebarKey) => {
      const text = sidebarKey.split('_').join(' ');
      return {
        singular: singular(text),
        plural: plural(text),
        sidebarKey
      };
    });
  });

  const renameMatchedTh = (th) => {
    const innerText = th?.innerText?.toLowerCase();
    const originalTitle = th?.innerText;

    if (!innerText) return;

    const matchedItem = matchingItems.value.find(
      (mi) => mi.singular === innerText || mi.plural === innerText
    );

    if (!matchedItem) return;
    const isInnerTextSingular = isSingular(innerText);

    const sidebarItem = menuNames.value[matchedItem.sidebarKey];
    if (!sidebarItem?.name) return;

    const modifiedName = isInnerTextSingular
      ? singular(sidebarItem?.name)
      : plural(sidebarItem?.name);

    if (
      innerText === modifiedName?.toLowerCase() ||
      th.getAttribute('data-set') === 'true'
    )
      return;

    th.innerText = textCapitalize(modifiedName);
    th.setAttribute('data-original-title', originalTitle);
    th.setAttribute('data-set', true);
  };

  const renameTableHeader = () => {
    const ths = document.querySelectorAll(headerClass);
    if (!ths?.length) return;

    for (const th of ths) {
      renameMatchedTh(th);
    }
  };

  onMounted(() => {
    renameTableHeader();
  });

  watch(
    () => isTeamSettingsLoaded.value,
    (val, oldVal) => {
      if (val && !oldVal) renameTableHeader();
    }
  );

  if (isRef(columns)) {
    watch(
      () => cloneDeep(columns.value),
      () => {
        setTimeout(() => {
          renameTableHeader();
        }, 200);
      }
    );
  }

  return {
    renameTableHeader,
    renameMatchedTh
  };
};

export default useRenameTableHeader;
