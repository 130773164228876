import axios from 'axios';
const state = {
    accessToken: null,
    authUser: null,
    members: [],
    roles: [],
    loading: false,
    userName: null,
    currentRole: '',
    countries: [],
    states: [],
    cities: [],
    profile: {},
    nationalities: [],
};

const mutations = {
    accessToken: (state, payload) => (state.accessToken = payload),
    members: (state, payload) => (state.members = payload),
    roles: (state, payload) => (state.roles = payload),
    loading: (state, payload) => (state.loading = payload),
    setAuthUser: (state, payload) => (state.authUser = payload),
    setCurrentRole: (state, payload) => (state.currentRole = payload),
    setCountries: (state, payload) => (state.countries = payload),
    setNationalities: (state, payload) => (state.nationalities = payload),
    setStates: (state, payload) => (state.states = payload),
    setCities: (state, payload) => (state.cities = payload),
    setProfile: (state, payload) => (state.profile = payload),
};
const getters = {
    getRecruitersList(state) {
        const user = state.members?.filter((member) =>
            member?.role?.includes('Recruiter')
        );
        return user?.map((i) => {
            return i?.user;
        });
    },
    getCurrentRole(state) {
        return state.currentRole;
    },
    isHiringManagerLoggedIn(state) {
        return state.currentRole === 'Hiring Manager';
    },
    isAdminLoggedIn(state) {
        return state.currentRole === 'Admin';
    },
    isRecruiterLoggedIn(state) {
        return state.currentRole === 'Recruiter';
    },
    isSuperRecruiterLoggedIn(state) {
        return state.currentRole === 'Recruiter' &&
            state.profile?.is_super_recruiter
            ? true
            : false;
    },
    recruiterPermissions(state) {
        return state.profile?.permissions?.permissions
            ? JSON.parse(state.profile?.permissions?.permissions)
            : null;
    },
    getHiringManagerList(state) {
        const user = state.members?.filter(
            (member) =>
                member?.role?.includes('Hiring Manager') &&
                member?.user?.is_active
        );
        return user?.map((i) => {
            return i?.user;
        });
    },
};
const actions = {
    async getStatesCities({ commit }, payload) {
        const stateUrl = `/admin-app/state-list/?country_${payload.filterBy}=${
            payload.filterBy === 'id' ? payload?.id : payload?.name
        }`;
        const cityUrl = `/admin-app/city-list/?state_${payload.filterBy}=${
            payload.filterBy === 'id' ? payload?.id : payload?.name
        }`;
        await axios
            .get(payload?.type === 'state' ? stateUrl : cityUrl)
            .then((res) => {
                if (res?.data?.length) {
                    if (payload?.type === 'state') {
                        commit('setStates', res?.data);
                    } else {
                        commit('setCities', res?.data);
                    }
                }
            });
    },
};
export default { state, mutations, getters, actions };
