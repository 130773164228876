<script setup>
import { computed, watch, ref, onMounted } from 'vue';
import useOrganization from '@/composables/recruitments/useOrganization';
import useCompany from './composables/recruitments/useCompany';
import useBusinessUnit from './composables/recruitments/useBusinessUnit';
import useDepartment from './composables/recruitments/useDepartment';
import useAuth from './composables/auth/useAuth';
import useAppConfig from '@/composables/app/useAppConfig';
import useCrud from '@/composables/app/useCrud';

import '@/assets/sass/app.scss';

import { useMeta } from '@/composables/use-meta';
import { useStore } from 'vuex';
import axios from 'axios';

import Toast from '@/views/common/Toast.vue';
import ConfirmModal from '@/views/common/ConfirmModal.vue';
import TextInputConfirmModal from '@/views/common/TextInputConfirmModal.vue';
import SendEmailModal from '@/views/common/SendEmailModal.vue';
import BulkParsing from '@/views/bulk-parsing/BulkParsing.vue';
import BulkFileUpload from '@/views/bulk-file-upload/BulkFileUpload.vue';
import ChangeStageStatus from '@/views/stage-change/ChangeStageStatus.vue';

useMeta({ title: 'ATS' });

const store = useStore();

const { getAllOrganizations } = useOrganization();
const { getAllCompanies } = useCompany();
const { getAllBusinessUnits } = useBusinessUnit();
const { getAllDepartments } = useDepartment();
const {
  getAllTeamMembers,
  getAllRoles,
  getCurrentRole,
  getCountriesList,
  getNationalityList,
  getProfileInformation,
  authMember
} = useAuth();
const { getData: getCustomFields } = useCrud('/admin-app/custom-field/');
const { getData: getMailboxConfiguration } = useCrud(
  '/customer/mailbox-configuration/'
);
const { getData: getUserSettings } = useCrud('/customer/user-setting/');
const { getData: getTeamSettings } = useCrud('/customer/admin-setting/');

const { currentWindowWidth } = useAppConfig();
const isDataSet = ref(false);
watch(
  () => store.state.auth.accessToken,
  async (val) => {
    if (val && !isDataSet.value) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + val;
    }
    await setCustomFields();
    store.dispatch('notification/getNotificationList', { page: 1 });
    getProfileInformation();
    getCurrentRole();
    getAllOrganizations();
    getAllCompanies();
    getAllBusinessUnits();
    getAllDepartments();
    getAllTeamMembers();
    getAllRoles();
    getCountriesList();
    getNationalityList();

    const userSettings = await getUserSettings();
    store.commit('settings/setUserSettings', userSettings);

    const teamSettings = await getTeamSettings();
    store.commit('settings/setTeamSettings', teamSettings);

    const mailboxConfiguration = await getMailboxConfiguration();
    store.commit('mailbox/set', mailboxConfiguration);

    store.commit('setStoreDataLoaded', true);
    isDataSet.value = true;
  }
);

onMounted(() => {
  currentWindowWidth.value = document.body.clientWidth;
});
window.onresize = () => {
  currentWindowWidth.value = document.body.clientWidth;
};

const setCustomFields = async () => {
  const response = await getCustomFields();
  const customFields = response?.data ? JSON.parse(response.data) : [];
  store.commit('masterSettings/masterView/customFields', customFields);
};

const layout = computed(() => {
  return store.getters.layout;
});
</script>

<script>
// layouts
import appLayout from '@/layouts/app-layout.vue';
import authLayout from '@/layouts/auth-layout.vue';

export default {
  components: {
    app: appLayout,
    auth: authLayout
  }
};
</script>

<template>
  <div :class="[$store.state.layout_style, $store.state.menu_style]">
    <component
      v-bind:is="layout"
      :is-data-set="isDataSet"
    ></component>
    <!-- toast -->
    <Toast />
    <!-- confirm modal -->
    <ConfirmModal />
    <!-- text Input confirm modal -->
    <TextInputConfirmModal />
    <!-- send email modal -->
    <SendEmailModal />

    <template v-if="isDataSet && authMember?.id">
      <BulkParsing />

      <!-- Bulk file upload modal -->
      <BulkFileUpload />

      <!-- change stage status modal -->
      <ChangeStageStatus />
    </template>
    <!-- Bulk parsing modal -->
  </div>
</template>

<style>
.form-control {
  height: auto;
  border: 1px solid #bfc9d4;
  color: #3b3f5c;
  font-size: 15px;
  letter-spacing: 0.8px;
  padding: 0.8rem 0.8rem;
  border-radius: 5px;
  height: 0;
  /* text-transform: capitalize; */
}
/* card for adding, viewing, updating */
.dark .panel-body .card {
  border: none !important;
  background: #060818 !important;
}
.card {
  border: none;
  background-color: #f1f2f3;
}
.animated-underline-content .nav-tabs li a {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.nav-link {
  padding: 0.2rem 0.6rem !important;
}
.animated-underline-content .tab-content > .tab-pane {
  padding: 0 !important;
}
.card-header {
  padding: 0.2rem 0.5rem !important;
}
.h4 {
  font-size: 1rem !important;
}
th,
td {
  padding: 10px !important;
}
.btn {
  padding: 3px 8px;
}
.modal-content .modal-footer [data-dismiss='modal'] {
  padding: 3px 8px;
}
.f {
  animation: fadeIn 1s;
}
.recruitments .table-button-container {
  margin-top: -40px;
}
.navbar .theme-brand li.theme-logo img {
  height: 34px;
  width: auto !important;
}
.table-nowrap {
  white-space: nowrap !important;
  width: 200px;
}
.VueTables__heading {
  white-space: nowrap !important;
  text-transform: capitalize !important;
}
.dark .v-select .vs__dropdown-toggle .vs__selected-options .vs__selected {
  background: #efefef;
  color: #2e2e2e;
  border: none;
}
input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  color: #060818;
}
.custom-table .resume-table .VueTables .row div {
  flex-direction: row !important;
}
.btn {
  text-transform: capitalize !important;
}
</style>
