<script setup>
import { ref, computed, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';

import ChangeStatusProcess from './ChangeStatusProcess.vue';

import useCrud from '@/composables/app/useCrud';
import useEnv from '@/composables/app/useEnv';
import useAuth from '@/composables/auth/useAuth';
import useCommonUtils from '@/composables/app/useCommonUtils';
import useToast from '@/composables/app/useToast';

const { getData: getProcessesRequest, deleteData: deleteProcessesRequest } =
  useCrud('/admin-app/stage-change-processes/');
const { websocketURL } = useEnv();
const { authMember } = useAuth();
const { isJsonParsable } = useCommonUtils();
const { showConfirmModalAsync, showDefaultToast, showToastFromResponse } =
  useToast();

const allProcesses = ref([]);
const showPopup = computed(() => Boolean(allProcesses.value?.length));
const minimized = ref(true);

const processes = computed(() => {
  return allProcesses.value;
});

const getProcesses = async () => {
  let data = await getProcessesRequest();
  if (!data?.length) data = [];
  data.sort((a, b) => {
    if (a?.updated_at || !b?.updated_at) return -1;
    return new Date(b?.updated_at) - new Date(a?.updated_at);
  });
  console.log('stage change data', data);
  allProcesses.value = data;
};

onBeforeMount(async () => {
  await getProcesses();
});

const socket = ref(null);
const socketUrl = computed(() => {
  const baseUrl = websocketURL.includes('/notifications')
    ? websocketURL.replace('/notifications', '')
    : websocketURL;
  return `${baseUrl}/stage_change/${authMember.value?.id}/`;
});

const onSocketOpen = () => {
  socket.value = new WebSocket(socketUrl.value);
  if (socket.value) {
    socket.value.onmessage = async (payload) => {
      let payloadData = isJsonParsable(payload?.data)
        ? JSON.parse(payload?.data)
        : {};

      console.log('stage change socket data', payloadData, payload);
      if (payloadData?.updated) {
        await getProcesses();
      }
    };
  }
};
const onSocketClose = () => {
  if (!socket.value) return;
  socket.value.close();
};
onMounted(() => {
  onSocketOpen();
});
onBeforeUnmount(() => {
  onSocketClose();
});

const statuses = computed(() => {
  const pendingWorkersList =
    allProcesses.value.filter(
      (process) => process?.type === 'pending_worker'
    ) || [];
  const pendingWorkerSuccessList =
    pendingWorkersList.filter((process) => process?.pending_worker_status) ||
    [];
  const pendingWorkerProcessingList =
    pendingWorkersList.filter(
      (process) =>
        !process?.pending_worker_status && !process?.pending_worker_sent_on
    ) || [];
  const pendingWorkerFailedList =
    pendingWorkersList.filter(
      (process) =>
        !process?.pending_worker_status && process?.pending_worker_sent_on
    ) || [];
  const pendingWorkerMessage = `Pending Worker: ${pendingWorkerSuccessList?.length} success, ${pendingWorkerProcessingList?.length} processing, ${pendingWorkerFailedList?.length} failed of total ${pendingWorkersList?.length}`;

  const dvmsList =
    allProcesses.value.filter((process) => process?.type === 'dvms') || [];
  const dvmsSuccessList =
    dvmsList.filter((process) => process?.dvms_status) || [];
  const dvmsProcessingList =
    dvmsList.filter(
      (process) => !process?.dvms_status && !process?.dvms_sent_on
    ) || [];
  const dvmsFailedList =
    dvmsList.filter(
      (process) => !process?.dvms_status && process?.dvms_sent_on
    ) || [];
  const dvmsMessage = `DVMS: ${dvmsSuccessList?.length} success, ${dvmsProcessingList?.length} processing, ${dvmsFailedList?.length} failed of total ${dvmsList?.length}`;

  return {
    pendingWorker: {
      success: pendingWorkerSuccessList,
      processing: pendingWorkerProcessingList,
      failed: pendingWorkerFailedList,
      message: pendingWorkerMessage,
      show: Boolean(pendingWorkersList?.length)
    },
    dvms: {
      success: dvmsSuccessList,
      processing: dvmsProcessingList,
      failed: dvmsFailedList,
      message: dvmsMessage,
      show: Boolean(dvmsList?.length)
    }
  };
});

const clearProcesses = async (processes = [], options = {}) => {
  if (!processes?.length) {
    return showDefaultToast('Error', 'danger', 'Nothing to clear');
  }
  if (options?.confirm) {
    const confirm = await showConfirmModalAsync({
      message: options?.confirmMessage
        ? options?.confirmMessage
        : 'Are you sure you want to clear selected?'
    });
    if (!confirm) return;
  }
  const response = await deleteProcessesRequest('', {
    queries: { process_ids: processes.map((process) => process?.id).join(',') },
    returnExactResponse: true
  });
  console.log('clearProcesses response', response);
  if (options?.toast) {
    showToastFromResponse(
      response,
      {
        type: response ? 'Success' : 'Error',
        variant: response ? 'success' : 'danger',
        message: response ? 'Deleted successfully' : 'Failed to delete'
      },
      false
    );
  }
  await getProcesses();
};
</script>

<template>
  <Teleport to="body">
    <div
      v-if="showPopup"
      class="bulk-parsing-container shadow-small bg-card d-flex flex-column"
    >
      <div
        class="w-full flex-between parsing-header bg-color-18 dark-bg-color-19"
      >
        <div>
          <div class="fw-bold text-color-2">Stage Change Status</div>
          <template
            v-for="(status, index) in Object.values(statuses)"
            :key="index"
          >
            <div
              v-if="status?.show"
              class="text-11px mb-1"
            >
              {{ status?.message }}
            </div>
          </template>
        </div>

        <div class="flex-start">
          <FeatherIcon
            :type="minimized ? 'maximize-2' : 'minimize-2'"
            size="20"
            class="cursor-pointer"
            @click="minimized = !minimized"
          />
        </div>
      </div>

      <div
        v-if="!minimized"
        class="p-3 parsing-body overflow-auto flex-1 border"
      >
        <div class="mb-3 w-full flex-center gap-1 flex-wrap">
          <button
            v-if="statuses?.pendingWorker?.show"
            class="btn btn-primary px-2 py-1"
            @click="
              clearProcesses([...statuses?.pendingWorker?.success], {
                confirm: true,
                toast: true,
                confirmMessage:
                  'Are you sure you want to clear all completed pending workers?'
              })
            "
          >
            Clear Completed Pending Workers
          </button>
          <button
            v-if="statuses?.dvms?.show"
            class="btn btn-secondary px-2 py-1"
            @click="
              clearProcesses([...statuses?.dvms?.success], {
                confirm: true,
                toast: true,
                confirmMessage:
                  'Are you sure you want to clear all completed dvms?'
              })
            "
          >
            Clear Completed Dvms
          </button>
          <button
            class="btn btn-warning px-2 py-1"
            @click="
              clearProcesses(
                [
                  ...statuses?.pendingWorker?.success,
                  ...statuses?.dvms?.success
                ],
                {
                  confirm: true,
                  toast: true,
                  confirmMessage:
                    'Are you sure you want to clear all completed?'
                }
              )
            "
          >
            Clear Completed
          </button>
          <button
            class="btn btn-danger px-2 py-1"
            @click="
              clearProcesses([...allProcesses], {
                confirm: true,
                toast: true,
                confirmMessage: 'Are you sure you want to clear all?'
              })
            "
          >
            Clear All
          </button>
        </div>

        <template
          v-for="process in processes"
          :key="process?.id"
        >
          <ChangeStatusProcess :process="process" />
        </template>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
.bulk-parsing-container {
  position: fixed;
  bottom: 0;
  right: 64px;
  width: 520px;
  max-width: calc(98% - 64px);
  max-height: calc(100% - 120px);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 2999;

  .parsing-header {
    padding: 12px 16px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top: 1px solid #3b3f5c;
    border-left: 1px solid #3b3f5c;
    border-right: 1px solid #3b3f5c;
    // height: 52px;
  }

  .cancel-btn {
    width: 39px;
    height: 39px;
  }
}
</style>
