<script setup>
import { ref, watch, computed, watchEffect, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Vselect from 'vue-select';
import { useStore } from 'vuex';
import 'vue-select/dist/vue-select.css';
import useToast from '@/composables/app/useToast';
import useCandidate from '@/composables/recruitments/useCandidate';
import useEnv from '@/composables/app/useEnv';
import useCrud from '@/composables/app/useCrud';
import useTableHeaderStickToTop from '@/composables/hooks/useTableHeaderStickToTop';
import useDate from '@/composables/app/useDate';
import useCommonUtils from '@/composables/app/useCommonUtils';
import { format, subDays } from 'date-fns';
import axios from 'axios';
import Modal from '@/views/common/Modal.vue';
import * as yup from 'yup';
import useValidation from '@/composables/app/useValidation';
import useHtmlEditor from '@/composables/app/useHtmlEditor';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const { createData: createNoteJob } = useCrud('/customer/job-note/');
const { createData: createNoteOffer } = useCrud('/customer/applicant-note/');

const store = useStore();
const { quillToolbar } = useHtmlEditor();
const { runYupValidation } = useValidation();
const getNotification = computed(() => {
    return store.getters['notification/getNotification'];
});
useTableHeaderStickToTop({
    scrollEl: '.scroll-el',
    targetOffsetTop: ['.VueTables .row'],
    stickySearchRow: true,
    offset: 4,
});

const { fitstCharsOfString, generateRandomString, stringCapitalize } =
    useCommonUtils();
const { showDefaultToast, showConfirmModal } = useToast();
const { serverUrl } = useEnv();

const { getData: getNotificationList, getRequestLoading: applicantsLoading } =
    useCrud('/customer/notifications/');
const { updateData: bulkNotificationAction } = useCrud(
    '/customer/bulk-notifications-update/'
);
const route = useRoute();
const router = useRouter();
const selectedTab = ref('');

// applicant table start
const showNotesModal = ref(false);
const selectedAction = ref('');
const job = ref(null);
const uploadingNote = ref(false);
const noteUpdateKey = ref(1);
const newNoteData = ref({
    title: '',
    description: '',
});
const newNoteErrors = ref({});
const noteSchema = yup.object().shape({
    title: yup.string().required(),
    description: yup.string().required(),
});

const count = ref('');
const items = ref([]);
const loading = ref(false);
const dataLoading = ref(false);
const firstTimeLoading = ref(true);
const filterByColumn = ref(true);
const requestQueryParams = ref({});
const processRequestParams = (data) => {
    if (selectedTab?.value) {
        data = { ...data, type: selectedTab?.value };
    }
    if (!data.orderBy) {
        data = { ...data, orderBy: 'id', ascending: 0 };
    }
    let { query, byColumn, ...rest } = data;
    if ('ascending' in rest) {
        rest = {
            ...rest,
            ascending:
                parseInt(rest.ascending) === 1
                    ? true
                    : parseInt(rest.ascending) === 0
                    ? false
                    : null,
        };
    }
    return { ...rest, query };
};
const allColumns = ['context', 'type', 'message', 'created_at', 'actions'];
const selectedNotification = ref([]);
const SingleSelectedNotification = ref(null);
const columns = ref(allColumns);
const orderedColumns = computed(() =>
    allColumns.filter((column) => columns.value.includes(column))
);
const onSelectNotification = (selectedNotificationList) => {
    selectedNotification.value = selectedNotificationList;
};
const selectedBulkAction = ref('');
const bulkActionOptions = computed(() => {
    return selectedTab?.value === 'archive'
        ? [{ title: 'Restore All' }]
        : [
              // { title: 'Approve All' },
              // { title: 'Deny All' },
              { title: 'Archive All' },
          ];
});
const { tableDateFilterOptions } = useDate();
const table_option = ref({
    perPage: 10,
    perPageValues: [1, 5, 10, 20, 50],
    skin: 'table',
    columnsClasses: { actions: 'actions text-center' },
    sortable: [],
    pagination: { nav: 'scroll', chunk: 5 },
    texts: {
        count: 'Showing {from} to {to} of {count}',
        filter: '',
        filterPlaceholder: 'Search...',
        limit: 'Show:',
    },
    sortable: ['context', 'type', 'message', 'created_at'],
    sortIcon: {
        base: 'sort-icon-none',
        up: 'sort-icon-asc',
        down: 'sort-icon-desc',
    },
    resizableColumns: false,
    selectable: {
        mode: 'multiple', // or 'multiple'
        only: function (row) {
            return true; // any condition
        },
        selectAllMode: 'all', // or 'page',
        programmatic: false,
    },
    filterByColumn: true,
    serverMultiSorting: true,

    listColumns: {
        created_at: tableDateFilterOptions,
    },
    requestFunction: async (data) => {
        requestQueryParams.value = processRequestParams({ ...data });
        const response = await getNotificationList({
            ...requestQueryParams.value,
        });
        if (response && 'results' in response && 'count' in response) {
            items.value = [...response.results];
            count.value = response.unread_count;
            firstTimeLoading.value = false;
            return Promise.resolve({
                data: response.results,
                count: response.count,
            });
        } else {
            return Promise.resolve({ data: [], count: 0 });
        }
    },
});
const server_table = ref(null);

const onTableRefresh = async () => {
    if (server_table.value) {
        await server_table.value.resetQuery();
    }
};

// set fixed scroolbar container height
const container = ref(null);
const containerHeight = ref('100%');
const handleBulkActions = async (type, list) => {
    dataLoading.value = true;
    const actionKey =
        type === 'Archive All'
            ? 'is_archive'
            : type === 'Approve All'
            ? 'is_approve'
            : type === 'Deny All'
            ? 'is_deny'
            : type === 'Restore All'
            ? 'is_archive'
            : '';
    const ids = list?.map((i) => {
        return {
            id: i?.id,
            [actionKey]: type === 'Restore All' ? false : true,
        };
    });
    const payload = {
        to_update: ids,
    };
    await bulkNotificationAction(payload)
        .then(async (res) => {
            if (res?.code === 0) {
                selectedBulkAction.value = '';
                selectedNotification.value = '';
                await onTableRefresh();
                await store.dispatch('notification/getNotificationList', {
                    page: 1,
                });
                showDefaultToast(res?.message, 'success');
                dataLoading.value = false;
            } else {
                dataLoading.value = false;
                showDefaultToast(res?.message, 'danger');
            }
        })
        .catch(({ message }) => {
            dataLoading.value = false;
            showDefaultToast(message, 'danger');
        });
};

const onSelectBulkAction = (event) => {
    showConfirmModal(
        { message: 'Are you sure to proceed?' },
        async (confirm) => {
            if (!confirm) return;
            const action_type = event?.title;
            if (action_type === 'Archive All') {
                handleBulkActions(action_type, selectedNotification.value);
            } else if (
                action_type === 'Approve All' ||
                action_type === 'Deny All'
            ) {
                const list = selectedNotification.value?.filter(
                    (i) =>
                        !i?.is_action_taken &&
                        i?.action === 'popup' &&
                        i?.type === 'approval'
                );
                if (list?.length > 0) {
                    handleBulkActions(action_type, list);
                } else {
                    showDefaultToast(
                        `No notification is ${
                            action_type === 'Approve All'
                                ? 'approvable'
                                : 'deniable'
                        }`,
                        'warning'
                    );
                }
            } else if (action_type === 'Restore All') {
                handleBulkActions(action_type, selectedNotification.value);
            }
        }
    );
};

const readNotification = async (id) => {
    const payload = {
        is_read: true,
    };
    await axios.patch(`/customer/notifications/${id}/`, payload);
    await onTableRefresh();
    await store.dispatch('notification/getNotificationList', { page: 1 });
};

const viewItem = async (notification) => {
    if (notification?.context === 'job' && notification?.job) {
        router.push(`/recruitments/jobs/${notification?.job}`);
    } else if (notification?.context === 'offer') {
        router?.push(
            `/recruitments/applicants?job_id=${notification?.job}&candidate_id=${notification?.candidate}`
        );
    } else if (notification?.context === 'documentation_upload') {
        router?.push(
            `/recruitments/onboarding-documents?job=${notification?.job}&applicant=${notification?.candidate}`
        );
        if (!notification?.is_read) {
            await readNotification(notification.id);
        }
    } else if (notification?.context === 'interview') {
        router?.push(
            `/recruitments/applicants?job_id=${notification?.job}&candidate_id=${notification?.candidate}`
        );
        if (!notification?.is_read) {
            await readNotification(notification.id);
        }
    }
};
const confirmedApproved = async () => {
    const payload = {
        is_approved: true,
        notification_id: SingleSelectedNotification.value.id,
        is_read: true,
        note_title: newNoteData.value.title,
        note_description: newNoteData.value.description,
    };

    dataLoading.value = true;
    await axios
        .patch(SingleSelectedNotification.value.request_url, payload)
        .then(async (res) => {
            if (res?.data?.code === 0) {
                await onTableRefresh();
                await store.dispatch('notification/getNotificationList', {
                    page: 1,
                });
                dataLoading.value = false;
                showDefaultToast(res?.data?.message, 'success');
            } else {
                dataLoading.value = false;
                showDefaultToast(res?.data?.message, 'danger');
            }
        });
};
const approveItem = async (notification) => {
    showConfirmModal(
        { message: 'Are you sure to approve ?' },
        async (confirm) => {
            if (!confirm) return;
            SingleSelectedNotification.value = notification;
            selectedAction.value = 'approve';
            newNoteData.value.title = '';
            newNoteData.value.description = '';
            newNoteErrors.value = {};
            noteUpdateKey.value = noteUpdateKey.value + 1;
            showNotesModal.value = true;
        }
    );
};
const confirmDeny = async () => {
    const payload = {
        is_approved: false,
        notification_id: SingleSelectedNotification.value.id,
        is_read: true,
        note_title: newNoteData.value.title,
        note_description: newNoteData.value.description,
    };
    dataLoading.value = true;
    await axios
        .patch(SingleSelectedNotification.value.request_url, payload)
        ?.then(async (res) => {
            if (res?.data?.code === 0) {
                await onTableRefresh();
                await store.dispatch('notification/getNotificationList', {
                    page: 1,
                });
                dataLoading.value = false;
                showDefaultToast(res?.data?.message, 'success');
            } else {
                dataLoading.value = false;
                showDefaultToast(res?.data?.message, 'danger');
            }
        });
};
const denyItem = async (notification) => {
    showConfirmModal(
        { message: 'Are you sure to reject ?' },
        async (confirm) => {
            if (!confirm) return;
            SingleSelectedNotification.value = notification;
            selectedAction.value = 'reject';
            newNoteData.value.title = '';
            newNoteData.value.description = '';
            newNoteErrors.value = {};
            noteUpdateKey.value = noteUpdateKey.value + 1;
            showNotesModal.value = true;
        }
    );
};
const deleteItem = async (notification) => {
    showConfirmModal(
        { message: 'Are you sure to archive?' },
        async (confirm) => {
            if (!confirm) return;
            const payload = {
                is_archive: true,
            };
            dataLoading.value = true;
            await axios
                .patch(`customer/notifications/${notification?.id}/`, payload)
                .then(async (res) => {
                    if (res?.data?.code === 0) {
                        await onTableRefresh();
                        await store.dispatch(
                            'notification/getNotificationList',
                            {
                                page: 1,
                            }
                        );
                        dataLoading.value = false;
                        showDefaultToast(res?.data?.message, 'success');
                    } else {
                        dataLoading.value = false;
                        showDefaultToast(res?.data?.message, 'danger');
                    }
                })
                .catch(({ message }) => {
                    dataLoading.value = false;
                    showDefaultToast(message, 'danger');
                });
        }
    );
};

const restoreItem = async (notification) => {
    showConfirmModal(
        { message: 'Are you sure to restore?' },
        async (confirm) => {
            if (!confirm) return;
            const payload = {
                is_archive: false,
            };
            dataLoading.value = true;
            await axios
                .patch(`customer/notifications/${notification?.id}/`, payload)
                .then(async (res) => {
                    if (res?.data?.code === 0) {
                        await onTableRefresh();
                        await store.dispatch(
                            'notification/getNotificationList',
                            {
                                page: 1,
                            }
                        );
                        dataLoading.value = false;
                        showDefaultToast(res?.data?.message, 'success');
                    } else {
                        dataLoading.value = false;
                        showDefaultToast(res?.data?.message, 'danger');
                    }
                })
                .catch(({ message }) => {
                    dataLoading.value = false;
                    showDefaultToast(message, 'danger');
                });
        }
    );
};
const confirmedNoteCreate = async () => {
    uploadingNote.value = true;
    if (selectedAction.value === 'approve') {
        await confirmedApproved();
    } else if (selectedAction.value === 'reject') {
        await confirmDeny();
    }
    uploadingNote.value = false;
    showNotesModal.value = false;
};
const onNoteSubmit = async () => {
    if (uploadingNote.value) return;
    if (selectedAction.value === 'reject') {
        const { validated, errors } = await runYupValidation(
            noteSchema,
            newNoteData.value
        );
        if (!validated) {
            newNoteErrors.value = { ...errors };
            return;
        }
        newNoteErrors.value = {};
        await confirmedNoteCreate();
    } else if (selectedAction.value === 'approve') {
        if (newNoteData.value.title) {
            await confirmedNoteCreate();
        } else {
            uploadingNote.value = false;
            showNotesModal.value = false;
            await confirmedApproved();
        }
    }
};
watch(
    () => store.getters['notification/getReloadNotificationPage'],
    (value) => {
        if (value) {
            onTableRefresh();
            store.commit('notification/reloadNotificationPage', false);
        }
    }
);

onMounted(async () => {
    if (container.value)
        containerHeight.value = `${container.value.clientHeight}px`;
});
</script>

<template>
    <div class="w-100 h-100 p-3 recruitments recruitments-organizations">
        <div
            ref="container"
            class="h-100 w-100 position-relative overflow-hidden panel rounded-1"
        >
            <PerfectScrollbar
                :settings="{ maxScrollbarLength: 150 }"
                class="col-xl-12 col-lg-12 col-sm-12 w-100 pb-3 scroll-el"
                :style="`height: ${containerHeight} !important`"
            >
                <div class="panel br-6 p-0">
                    <div
                        v-if="dataLoading"
                        class="d-flex flex-column table-loading"
                    >
                        <p class="placeholder-glow mb-2">
                            <span class="col-9 placeholder"></span>
                        </p>
                        <p class="placeholder-glow mb-2">
                            <span class="col-12 placeholder"></span>
                        </p>
                        <p class="placeholder-glow mb-2">
                            <span class="col-6 placeholder"></span>
                        </p>
                    </div>

                    <div
                        v-else
                        class="custom-table"
                        :class="[
                            !firstTimeLoading && loading
                                ? 'server-table-loading'
                                : '',
                            !filterByColumn ? 'filter-by-column-disabled' : '',
                        ]"
                    >
                        <v-server-table
                            ref="server_table"
                            :columns="orderedColumns"
                            :options="table_option"
                            @select="onSelectNotification"
                            @loading="loading = true"
                            @loaded="loading = false"
                        >
                            <template #afterFilterWrapper>
                                <div class="d-flex flex-row align-items-center">
                                    <Vselect
                                        :disabled="
                                            !selectedNotification?.length > 0
                                        "
                                        v-model="selectedBulkAction"
                                        :options="bulkActionOptions"
                                        label="title"
                                        placeholder="Select Bulk Action"
                                        :multiple="false"
                                        class="no-tags me-3"
                                        style="min-width: 210px"
                                        :clearable="false"
                                        @option:selecting="
                                            onSelectBulkAction($event)
                                        "
                                    >
                                    </Vselect>
                                    <button
                                        @click="
                                            selectedTab = null;
                                            onTableRefresh();
                                        "
                                        :class="`btn  me-2 px-3 py-2 d-flex align-items-center flex-row justify-content-center ${
                                            !selectedTab
                                                ? 'btn-primary'
                                                : 'btn-outline-primary'
                                        }`"
                                    >
                                        <span>All</span>
                                        <span
                                            v-if="count"
                                            class="badge bg-primary ms-1"
                                            >{{ count }}</span
                                        >
                                    </button>
                                    <button
                                        @click="
                                            selectedTab = 'job';
                                            onTableRefresh();
                                        "
                                        :class="`btn  me-2 px-3 py-2 d-flex flex-row justify-content-center align-items-center  ${
                                            selectedTab === 'job'
                                                ? 'btn-primary'
                                                : 'btn-outline-primary'
                                        }`"
                                    >
                                        <span>Job</span>
                                        <!-- <span class="badge bg-secondary ms-2">45</span> -->
                                    </button>
                                    <button
                                        @click="
                                            selectedTab = 'offer';
                                            onTableRefresh();
                                        "
                                        :class="`btn  me-2 px-3 py-2 d-flex flex-row justify-content-center align-items-center  ${
                                            selectedTab === 'offer'
                                                ? 'btn-primary'
                                                : 'btn-outline-primary'
                                        }`"
                                    >
                                        <span>Offer</span>
                                        <!-- <span class="badge bg-info ms-2">45</span> -->
                                    </button>
                                    <button
                                        @click="
                                            selectedTab = 'archive';
                                            onTableRefresh();
                                        "
                                        :class="`btn  me-2 px-3 py-2 d-flex flex-row justify-content-center align-items-center  ${
                                            selectedTab === 'archive'
                                                ? 'btn-primary'
                                                : 'btn-outline-primary'
                                        }`"
                                    >
                                        <span>Archive</span>
                                        <!-- <span class="badge bg-info ms-2">45</span> -->
                                    </button>
                                    <button
                                        class="btn btn-outline-info me-2 px-3 py-2 d-flex flex-row justify-content-center align-items-center"
                                        @click="onTableRefresh"
                                    >
                                        <FeatherIcon
                                            type="refresh-ccw"
                                            stroke-width="2"
                                            class="me-1"
                                            size="100"
                                        />
                                        <span class="d-none d-md-block"
                                            >Refresh</span
                                        >
                                    </button>
                                    <button
                                        class="btn me-1 px-2 py-2 d-flex flex-row justify-content-center align-items-center"
                                        :class="[
                                            filterByColumn
                                                ? 'btn-info'
                                                : 'btn-outline-info',
                                        ]"
                                        @click="
                                            filterByColumn = !filterByColumn
                                        "
                                    >
                                        <FeatherIcon
                                            type="filter"
                                            stroke-width="2"
                                            class="me-1"
                                        />
                                        <span class="d-none d-md-block"
                                            >Filter</span
                                        >
                                    </button>
                                </div>
                            </template>
                            <template #context="props">
                                {{ stringCapitalize(props?.row?.context) }}
                            </template>
                            <template #type="props">
                                {{ stringCapitalize(props?.row?.type) }}
                            </template>
                            <template #message="props">
                                {{ props?.row?.message }}
                            </template>
                            <template #created_at="props">
                                {{
                                    props?.row?.created_at
                                        ? format(
                                              new Date(props?.row?.created_at),
                                              'dd/MM/yyyy'
                                          )
                                        : ''
                                }}
                            </template>
                            <template #actions="props">
                                <div class="d-flex align-items-center gap-3">
                                    <button
                                        class="btn btn-primary"
                                        @click="viewItem(props.row)"
                                    >
                                        View
                                    </button>
                                    <button
                                        v-if="
                                            props.row?.action === 'popup' &&
                                            !props.row?.is_action_taken
                                        "
                                        @click="approveItem(props.row)"
                                        class="btn btn-success"
                                    >
                                        Approve
                                    </button>
                                    <button
                                        v-if="
                                            props.row?.action === 'popup' &&
                                            !props.row?.is_action_taken
                                        "
                                        @click="denyItem(props.row)"
                                        class="btn btn-danger"
                                    >
                                        Reject
                                    </button>
                                    <button
                                        v-if="
                                            props.row?.is_read &&
                                            !props?.row?.is_archive
                                        "
                                        @click="deleteItem(props.row)"
                                        class="btn btn-danger"
                                    >
                                        Archive
                                    </button>
                                    <button
                                        v-if="
                                            props.row?.is_read &&
                                            props?.row?.is_archive
                                        "
                                        @click="restoreItem(props.row)"
                                        class="btn btn-danger"
                                    >
                                        Restore
                                    </button>
                                </div>
                            </template>
                        </v-server-table>
                    </div>
                </div>
            </PerfectScrollbar>
        </div>
    </div>
    <Modal
        v-model="showNotesModal"
        :options="{ backdrop: 'static' }"
        :centered="true"
        :scrollable="true"
    >
        <div class="modal-header">
            <h6 v-if="selectedAction === 'reject'" class="modal-title">
                Note is required for the
                <span class="text-primary">Reject</span> status
            </h6>
            <h6 v-else-if="selectedAction === 'approve'" class="modal-title">
                You can add note for
                <span class="text-primary">approve</span> status (Optional)
            </h6>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            ></button>
        </div>

        <div class="modal-body">
            <div class="w-100">
                <div id="note-editor" class="w-100">
                    <div class="form-group w-100">
                        <div
                            class="d-flex flex-row justify-content-between align-items-center"
                        >
                            <label
                                >Title
                                <small v-if="selectedAction === 'approve'"
                                    >(is required to save note)</small
                                ></label
                            >
                        </div>
                        <input
                            v-model="newNoteData.title"
                            class="form-control h-min-content py-2"
                        />
                        <div
                            v-if="newNoteErrors && newNoteErrors.title"
                            class="mt-1 text-danger text-12px"
                        >
                            {{ newNoteErrors.title }}
                        </div>
                    </div>

                    <div class="form-group w-100">
                        <label>Description</label>
                        <QuillEditor
                            v-model:content="newNoteData.description"
                            content-type="html"
                            class="bg-body"
                            :toolbar="quillToolbar"
                            :key="noteUpdateKey"
                        />
                        <div
                            v-if="newNoteErrors && newNoteErrors.description"
                            class="mt-1 text-danger text-12px"
                        >
                            {{ newNoteErrors.description }}
                        </div>
                    </div>

                    <div class="w-100 d-flex justify-content-end my-3">
                        <button
                            class="btn btn-secondary px-4 py-2"
                            @click="onNoteSubmit"
                        >
                            <div
                                v-if="uploadingNote"
                                class="spinner-border spinner-border-sm me-1"
                                role="status"
                                style="width: 10px; height: 10px"
                            >
                                <span class="visually-hidden">Loading...</span>
                            </div>

                            {{ uploadingNote ? 'Processing' : 'Submit' }}
                        </button>
                    </div>
                </div>
            </div>
        </div></Modal
    >
</template>
